<template>
  <div
      v-for="(item, index) in options_filterChips"
      :key="`dark_radio_filter_chips__${name}_${index}`"
  >
    <input
        type="radio"
        :value="valueObj==null? item : item[valueObj]"
        :name="name"
        :id="`dark_radio_filter_chips__${name}__${index}`"
        style="display: none"
        :checked="valueObj==null? item === modelValue : item[valueObj] === modelValue"
        @click="toggleRadio(index)"
    >
    <label class="chip_personalized" :for="`dark_radio_filter_chips__${name}__${index}`">
      {{ item.name }}
    </label>
  </div>
</template>

<script>
import { defineComponent } from "vue"
export default defineComponent({
  name: "DarkRadioFilterChip",

  emits: ['update:modelValue', 'filterChanged'],

  props: ['options_filterChips', 'modelValue', 'name', 'valueObj'],

  setup(props, { emit }) {
    const toggleRadio = (index) => {
      let selectedValue
      if (props.valueObj != null) {
        selectedValue = props.options_filterChips[index][props.valueObj];
      } else {
        selectedValue = props.options_filterChips[index];
      }
      if (selectedValue === props.modelValue) {
        // Si el mismo valor está seleccionado, no se hace nada
        return
      }
      emit("update:modelValue", selectedValue);
      emit("filterChanged")
    };

    return { toggleRadio };
  },
})
</script>

<style scoped>
  input + .chip_personalized {
    background-color: var(--complementary-second-800);
    border: 1px solid var(--complementary-second-800);
  }

  input + .chip_personalized:hover { box-shadow: 0 2px 5px rgba(0, 0, 0, .3); }

  input + .chip_personalized:not(:hover) { box-shadow: 0 2px 5px rgba(0, 0, 0, 0); }

  input + .chip_personalized:active {
    background-color: var(--complementary-second-700);
    border: 1px solid var(--complementary-second-700);
  }

  input:checked + .chip_personalized {
    background-color: var(--primary) !important;
    border: 1px solid var(--primary) !important;
    color: var(--text-white);
    font-weight: var(--medium);
  }

  input:checked + .chip_personalized::before {
    content: url("../../../assets/images-new/simbolo_barrilito_chips.svg");
    display: block;
    width: 24px;
    height: 24px;
    position: relative;
    top: -11px;
    left: -25px;
  }
</style>