<template>
  <article class="carta__card" style="background-image: url({{category.img}})" data-aos="fade-up">
    <div class="carta__nombre">
      <h3>{{category.name}}</h3>
    </div>
    <div class="carta__hover">
      <h3>{{category.name}}</h3>
      <ButtonPrimary>{{ $t('buttons.txt_ordena_ahora') }}</ButtonPrimary>
    </div>
  </article>
</template>

<script>
import ButtonPrimary from "@/components/new/buttons/ButtonPrimary.vue";

export default {
  name: 'CategoriasCarta',
  components: {ButtonPrimary},
  props: ['category'],
}

</script>

<style scoped>
  .carta__card{
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 150px;
    border-radius: 8px;
    cursor: pointer;
    overflow: hidden;
  }

  .carta__card h3{
    color: var(--text-white);
    font-family: var(--headings-font-family);
    font-size: 32px;
  }

  .carta__nombre{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 10px 20px;
    box-shadow: 0px -80px 40px rgba(0, 0, 0, .6) inset;
    opacity: 1;
    transition: opacity .3s linear;
  }

  .carta__nombre h3{
    margin-top: 80px;
    text-align: left;
    font-family: var(--headings-font-family);
    font-size: 32px;
  }

  .carta__hover{
    position: absolute;
    top: -100%;
    width: 100%;
    height: 100%;
    opacity: 0;
    box-shadow: 0px 0px 400px rgba(0, 0, 0, .8) inset;
    transition: opacity .3s linear;
  }

  .carta__card:hover{
    flex-direction: column-reverse;
  }

  .carta__card:hover .carta__nombre{
    top: -100%;
    opacity: 0;
  }

  .carta__card:hover .carta__hover{
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    opacity: 1;
  }

  @media only screen and (min-width: 390px){
    .carta__card{
      height: 180px;
    }
    .carta__nombre h3{
      margin-top: 120px;
    }
  }

  @media only screen and (min-width: 425px){
    .carta__card{
      height: 250px;
    }
    .carta__nombre h3{
      margin-top: 180px;
    }
  }

  @media only screen and (min-width: 768px){
    .carta__card{ width: 48%; }
    .carta__nombre h3{ margin-top: 190px; }
  }

  @media only screen and (min-width: 1024px){
    .carta__card{ width: 425px; }
  }
</style>