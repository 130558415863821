<template>
  <div class="modal__bg">
    <div class="modal_confirm">
      <img
          class="mi_campana"
          src="@/assets/user/icon_campana.svg"
          alt=""
      >
      <p class="tittle_modal_confirm">
        {{ $t('modallocalschedules.txt_local_cerrado_nuestros_horarios_son') }}
      </p>
      <ul class="submenu">
        <li
            v-for="(horario, index) in this.orderLocal.day_list"
            :key="`h__${index}`"
        ><p>
          ● {{getDayName(index)}}:
        </p>
          <div class="wrapper__schedule_hours">
            <p v-if="horario.length === 0">
              {{ $t('cobertura.txt_cerrado') }}
            </p>
            <p
                style="font-weight: var(--regular);"
                v-for="(hora, index_new) in horario"
                :key="`hora__${index_new}`"
            >
              {{getDayTime(hora)}}
            </p>
          </div>

        </li>
      </ul>
      <p class="opciones_ok_no">
        <span @click="clickOk">{{ok}}</span>
        <span
            class="no"
            @click="$emit('no')"
        >{{no}}</span>
      </p>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import moment from "moment";

export default {
  name: "ModalLocalSchedules",
  emits: ['sendOrder', 'ok', 'no'],
  props: ['ok', 'no', 'message', 'sendOrder'],
  components: {
  },
  data() {
    return {

    }
  },
  methods: {
    clickOk () {
      if (this.sendOrder) {
        this.$emit('sendOrder')
      } else {
        this.$emit('ok')
      }
    },

    getDayName(position) {
      return this.diasDeLaSemana[position]
    },
    getDayTime(object) {
      return `${moment(object.time_init, 'HH:mm:ss').format('hh:mm a')} - ${moment(object.time_end, 'HH:mm:ss').format('hh:mm a')}`
    },
  },

  computed: {
    ...mapState(["orderLocal", 'diasDeLaSemana'])
  }

}
</script>

<style scoped>

.submenu{
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  transition: all .3s linear;
  max-height: 80vh;
  width: fit-content;
  margin-inline: auto;
}

.submenu li{
  display: grid;
  grid-template-columns: 80px 1fr;
  gap: 10px;
  padding-left: 10px;
}

.submenu p{ font-size: 14px; }

.submenu p:first-child{
  font-weight: var(--extrabold);
  text-align: start;
}

.wrapper__schedule_hours {
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.modal__bg {
  flex-direction: column;
  font-size: 10px;
  z-index: 999;
}

.modal__bg > .modal_confirm {
  background: white;
  display: flex;
  flex-direction: column;
  border: 0.4em solid #FFFFFF;
  border-radius: 1.5em;
  width: 90%;
  color: #525252;
  font-weight: 500;
  font-size: 1.6em;
  text-align: center;
  position: relative;
  max-height: 80vh;
}

.modal__bg > .modal_confirm .mi_campana{
  position: absolute;
  right: -20px;
  top: -20px;
}

.modal__bg > .modal_confirm > .tittle_modal_confirm {
  margin-bottom: 0;
  padding: 5%;
}

.modal__bg > .modal_confirm > .opciones_ok_no{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-top: 0.4em solid #FFFFFF;
  margin-bottom: 0;
}

.modal__bg > .modal_confirm > .opciones_ok_no > span{
  width: 50%;
  text-align: center;
  cursor: pointer;
  transition: 0.4s ease all;
}

.modal__bg > .modal_confirm > .opciones_ok_no > span:hover{
  color: var(--primary);
  transition: 0.3s ease all;
}

.modal__bg > .modal_confirm > .opciones_ok_no > .no{
  border-right: 0.4em solid #FFFFFF;
}

.modal__bg > .modal_confirm > .opciones_ok_no > span:hover{
  font-weight: bold;
  color: var(--primary);
}

@media only screen and (min-width: 768px) {
  .modal__bg { font-size: 12px; }
  .modal__bg > .modal_confirm { width: 50%; }
}

@media only screen and (min-width: 992px) {
  .modal__bg { font-size: 13px; }
  .modal__bg > .modal_confirm { width: 45%; }
}

@media only screen and (min-width: 1200px) {
  .modal__bg > .modal_confirm { width: 35%; }
}

.submenu li{
  grid-template-columns: 110px 1fr;
  gap: 10px;
}

.submenu p{ font-size: 16px; }
</style>